<template>
  <ul id="main-menu-navigation" class="nav navbar-nav">
    <component :is="resolveNavComponent(item)" v-for="item in items" :key="item.header || item.title" :item="item" v-show="$Can(item.Permission)" />
    <div  class="d-flex justify-content-end align-items-center ml-auto"> <!-- Add ml-auto class here -->

      <b-button
              size="sm"
              variant="outline-primary"
              id="checker-button"
              @click="jobsModal"


            >
            <feather-icon
                icon="CalendarIcon"
                size="15"
              />
              
            
            </b-button>
            
    <b-modal  id="jobs-modal-2" cancel-variant="outline-secondary" ok-title="Ok" cancel-title="Cancel" :no-close-on-backdrop="true" centered @hidden="resetModal">
                            <template #modal-header="{ close }">
                                <span>See Jobs</span>
                            </template>
                            <div>

                              <b-card>
                                <b-overlay :show="formShow" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-fade class="modal-overlay">

          <b-row>
              <b-col cols="12" xl="12" md="12" sm="12" v-if="employeeId == undefined">
                  <b-form-group label="Employee" label-for="Employee">
                      <v-select v-model="selectedEmployee" :options="users" label="full_name" input-id="customer-data" :clearable="false"> </v-select>
                  </b-form-group>
              </b-col>

              <b-col cols="12" xl="12" md="12" sm="12">
                  <b-form-group label="Data Range" label-for="Data Range">
                      <flat-pickr placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="config" />
                  </b-form-group>
              </b-col>

              <b-col cols="12" xl="12" md="12" sm="12">
                  <b-form-group >
                      <b-button class="btn btn-sm" @click="getAvailabilityData" variant="success">Check</b-button>

                  </b-form-group>
              </b-col>

           
          </b-row>

 
                      <b-row class="mt-2" v-if="availabilityData.length>0">

                            <span style="border: 1px solid lightgray; flex:auto;justify-content: center; align-items: center;">
                            <p class="text-danger text-center p-1">
                              Employee is assigned to {{ availabilityData.length }} jobs within the selected dates. <br>
                              Please click on the job to view the details.
                            </p>
                          </span>

                              <!-- <b-button class="btn btn-sm" @click="jobsModal">See Jobs</b-button> -->
                              <b-col cols="12">

                                <div class="jobTable mt-2">
                                <b-table
                                  striped
                                  hover
                                  ref="refUserListTable"
                                  class="position-relative"
                                  :items="availabilityData"
                                  filter-debounce="250"
                                  responsive
                                  :fields="tableColumns"
                                  :tbody-tr-class="(item) => isWeekend(item.date) ? 'bg-secondary text-light' : ''"
                                  @row-selected="onRowSelected"
                                  selectable
                                  select-mode="single"
                              >


                                  <template #cell(date)="data">
                                      {{ dateFormat(data.value) }}
                                  </template>

                                  <template #cell(status)="data">
                                      <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
                                          Not Worked
                                      </b-badge>

                                      <b-badge v-if="data.value == 'S'" pill :variant="`light-warning`" class="text-capitalize">
                                          Sick
                                      </b-badge>

                                      <b-badge v-if="data.value == 'H'" pill :variant="`info`" class="text-capitalize">
                                          Holiday
                                      </b-badge>

                                      <b-badge v-if="data.value == 'E'" pill :variant="`primary`" class="text-capitalize">
                                          {{ data.item.project }}
                                      </b-badge>

                                      <b-badge v-if="data.value == 'O'" pill :variant="`light-primary`" class="text-capitalize">
                                          Worked Overtime
                                      </b-badge>

                                      <b-badge v-if="data.value == 'W'" pill :variant="`danger`" class="text-capitalize">
                                          AWOL
                                      </b-badge>
                                  </template>


                              </b-table>


                              </div>


                              </b-col>
                             
                        </b-row>
                        <b-row v-else>
                          <span style="border: 1px solid lightgray; flex:auto;justify-content: center; align-items: center;">
                            <p class="text-success  text-center p-1">
                            Employee is available on the selected dates.

                          </p>

                          </span>
                       

                        </b-row>
                      </b-overlay>


          </b-card>
          </div>

    </b-modal>


     
    </div>

 
  </ul>
</template>

<script>
import HorizontalNavMenuHeaderLink from '../horizontal-nav-menu-header-link/HorizontalNavMenuHeaderLink.vue';
import HorizontalNavMenuHeaderGroup from '../horizontal-nav-menu-header-group/HorizontalNavMenuHeaderGroup.vue';
import axiosIns from "@/libs/axios";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import router from '@/router';

import {
 
    BButton,
    BPopover,

  } from "bootstrap-vue";


export default {
  components: {
    HorizontalNavMenuHeaderLink,
    HorizontalNavMenuHeaderGroup,
    BButton,
    BPopover,
    flatPickr,
    vSelect

  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  data: function() {
     

        var availabilityData = [
            {
                date: null,
                status: null,
            },
        ];

        const availabilityOptions = [
            { label: "Available", value: "A" },
            { label: "Holiday", value: "H" },
            { label: "Sick", value: "S" },
            { label: "AWOL", value: "W" },
        ];
        return {

         

            selectedEmployee: null,
            availabilityData: availabilityData,
            employeeId:null,

            availabilityOptions,

            formShow: false,
            permissions: [],
            users: [],
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth() + 1,
            availability: "A",

            startDate: null,
            endDate: null,
            rangeDate: null,

            fetchAvailability: [],

  

            totalRows: 0,

            mainProps: {
                blank: true,
                blankColor: "#777",
                width: 75,
                height: 75,
                class: "m1",
            },

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },

            config: {
                dateFormat: "d-m-Y",
                mode: "range",
                locale: { firstDayOfWeek: 1 },
            },

            perYearOptions: [2023, 2024],
            perYear: 2023,

            tableColumns: [
                { key: "date", sortable: true, class: "text-center" },
                { key: "status", sortable: true, class: "text-center" },

            ],

            teamTableColumns: [
                { key: "id", sortable: true, class: "text-center" },
                { key: "title", sortable: true, class: "text-center" },
                { key: "foreman", sortable: true, class: "text-center" },
                { key: "staff_count", sortable: true, class: "text-center" },
                { key: "day_rate", sortable: true, class: "text-center" },
                { key: "status", sortable: true, class: "text-center" },
                { key: "hotel", sortable: true, class: "text-center" },

            ],
        };
    },

    created() {
        var dateNow = new Date();
        var firstDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)).format("DD-MM-YYYY");
        var lastDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("DD-MM-YYYY");
        this.rangeDate = String(firstDate) + " to " + String(lastDate);
        //this.selectedEmployee = this.employeeId;
        this.getAvailabilityData();

    },


  setup() {
    const resolveNavComponent = (item) => {
      if (item.children) return 'horizontal-nav-menu-header-group';
      return 'horizontal-nav-menu-header-link';
    };

    return {
      resolveNavComponent,
    };
  },

  methods:{
    getAvailabilityData() {
            this.formShow = true;
            this.availabilityData = [];

            var tempData = {
                startDate: null,
                endDate: null,
                userId: null,
            };

            if (this.employeeId == undefined) {
                tempData.userId = this.selectedEmployee ? this.selectedEmployee.id : null;
            } else {
                tempData.userId = this.employeeId;
            }

            if (this.rangeDate != null) {
                if (this.rangeDate.includes("to")) {
                    tempData.startDate = this.rangeDate.split(" to ")[0];
                    tempData.endDate = this.rangeDate.split(" to ")[1];
                } else {
                    tempData.startDate = this.rangeDate;
                }
            }

            axiosIns
          //.get("user", { params: queryParams })
          .get('availabilityData',  { params: tempData } )
          .then((response) => {
                    
                    this.users = response.data.userList;
                    if (this.selectedEmployee == null) {
                        // if (this.employeeId == undefined) {
                        this.selectedEmployee = this.users[0];


                        // } else {
                        //   this.selectedEmployee = this.users.find((user) => (user.id = this.employeeId));
                        // }
                    }

                    response.data.data.forEach(element => {
                      if(element.status != 'A'){
                        this.availabilityData.push(element)
                      }
                    });


                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;

                    this.$swal({
                        title: "Error",
                        text: "Failed to create report. Please try again or contact support.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });
                });
        },

        jobsModal(){
          this.$bvModal.show('jobs-modal-2'); 

        },

        resetModal(){

          this.availabilityData = [];

          var dateNow = new Date();
        var firstDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)).format("DD-MM-YYYY");
        var lastDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("DD-MM-YYYY");
        this.rangeDate = String(firstDate) + " to " + String(lastDate);
        this.selectedEmployee = this.users[0];


        },

        onRowSelected(item) {

          if(item[0].projectId>0){
            router.push({ name: 'event-view', params: { id: item[0].projectId } });
            this.$bvModal.hide('jobs-modal-2');
        }

          


        },



        dateFormat(val) {
            moment.locale("en-US");

            return moment(val, "DD-MM-YYYY").format("DD/MM/YYYY");
        },

        isWeekend(dateStr) {
            if(dateStr){

                const [day, month, year] = dateStr.split('-');

                // Create a new date object using the components in "Y-m-d" format
                const parsedDate = new Date(`${year}-${month}-${day}`);

                // Get the day of the week (0 for Sunday, 1 for Monday, and so on)
                const dayOfWeek = parsedDate.getDay();

                // Check if the day is Saturday (6) or Sunday (0), which are considered weekends
                return dayOfWeek === 6 || dayOfWeek === 0;


            }
            
        },
        
  }
};
</script>

<style lang="scss">

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.jobTable{
  height:500px;
  overflow-x: scroll;
}

</style>
